.main_container {
    display: flex;
    flex: 1;
    font-family: 'Major Mono Display', monospace;
    font-size: 1.5rem;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    cursor: pointer;
    border: 2px solid;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    line-height: 2rem;
    font-weight: 600;
    align-items: center;
}

.btn_icon {
    font-size: 2rem;
}