



.go_back {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: blue;
  z-index: 10;
}

.app_logo_container {
  align-items: center;
  padding: 2.5rem;
}

.home_section {
  min-height: 100vh;
  text-align: center;
  background-color: #231f20;
  color: white;
}

.home_section_content {
  display: flex;
  flex: 1;
  padding: 2rem;
}

.services_section {
  display: flex;
  height: 100vh;
  background-color: white;
  color: #231f20;
}

.services_content {
  flex: 1;
  padding: 1em 3em;
}


@media (max-width:700px){
  .vertical_text {
    font-family: 'Major Mono Display', monospace;
    writing-mode: horizontal-tb;
    text-orientation: sideways;
    font-size: 2.5rem;
    text-align: center;
    align-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .section_flex_orientation {
    flex-direction: column;
  }

  .link_container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .App-logo {
    width: 80%;
    pointer-events: none;
  }
}

@media (min-width:700px){
  .vertical_text {
    font-family: 'Major Mono Display', monospace;
    writing-mode: vertical-rl;
    text-orientation: sideways;
    font-size: 2.5rem;
    text-align: center;
    align-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .section_flex_orientation {
    flex-direction: row;
  }



  .link_container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }

  .App-logo {
    max-width: 30rem;
    pointer-events: none;
  }
  
}

.section_title_light {
  background-color: #231f20;
  color: white;
}

.section_title_dark {
  background-color: white;
  color: #231f20;
}

.history_section {
  display: flex;
  min-height: 100vh;
  color: #231f20;
  background-color: white;
}

.history_content p {
  font-family: 'Merriweather';
  font-size: 1.5rem;
  line-height: 3rem;
  text-align: justify;
}

.history_content li {
  margin-bottom: 2rem;
}

.history_content {
  flex: 1;
  padding: 3em 3em;
  align-items: center;
}

.work_with_us_section {
  display: flex;
  min-height: 100vh;
  background-color: #231f20;
  color: white;
}

.work_with_us_content {
  flex: 1;
  padding: 1em 3em;
  align-content: center;
  
}
.work_with_us_content p {
  font-family: 'Merriweather';
  font-size: 1.5rem;
  line-height: 3rem;
  text-align: justify;
}

.collaborate_with_us_section {
  display: flex;
  min-height: 100vh;
  background-color: white;
  color: #231f20;
}

.collaborate_with_us_content {
  flex: 1;
  padding: 1rem 3rem;
  padding-bottom: 3rem;
  align-items: center;
  
}
.collaborate_with_us_content p {
  font-family: 'Merriweather';
  font-size: 1.5rem;
  line-height: 3rem;
  text-align: justify;
}

.form {
  font-family: 'Merriweather';
  font-size: 1.5rem;
  padding: 1.5rem;
  background-color: #037fa3;
  color: white;
  text-decoration: none;
  border-radius: 1rem;
}

.footer_header {
  font-family: 'Major Mono Display', monospace;
  font-weight: 700;
  background-color: #037fa3;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.5rem;
}

.footer_header_icons_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.footer_icon {
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}

.footer {
  background-color: #231f20;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  padding: 1rem;
  align-items: top;
}

.footer_title {
  font-size: 1.3rem;
}

.footer_content {
  font-family: 'Merriweather';
  font-size: 1rem;
  color: white;
  flex: 2;
}


.footer-logo-container {
  align-items: center;
  padding: 2.5rem;
}

.footer-logo {
  width: 20%;
  pointer-events: none;
}

.footer_row {
  color: white;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
}

.footer_work_in_progress {
  font-family: 'Major Mono Display', monospace;
  font-weight: 700;
  background-color: #037fa3;
  color: white;
  text-align: center;
  padding: 1rem;
  line-height: 1rem;
}

.blue_text {
  color: #037fa3;
  font-weight: bold;
  text-decoration: underline;
}

